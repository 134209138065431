<script setup lang="ts">
import type { WatchStopHandle } from 'vue';
import { isNavigationFailure, NavigationFailureType } from 'vue-router';
import type { RouteLocationNormalized } from 'vue-router';
import { loadScript, scrollToAnchor, isClient } from '@mop/shared/utils/util';
import { localStorageGet, localStorageRemove, localStorageSet } from '@mop/shared/utils/localStorage';
import type { Timer } from '@mop/types';
import { logError } from '@mop/shared/utils/logger';

// @ts-ignore
import cssExport from '@/assets/scss/export.module.scss';

defineOptions({
  name: 'LayoutDefault',
});

const nuxtApp = useNuxtApp();
const errorRef = ref(false);
const requestEvent = useRequestEvent();

const {
  $mopConfig,
  $mopI18n,
  $cookie,
  $gtm2,
  $scroll,
  $resize,
  $breakpoint,
  $storyblokLivePreview,
  $maintenance,
  $datadog,
} = nuxtApp;
const classNameRef = ref([$storyblokLivePreview.isEnabledInIframe ? 'wrapper__iframe-live-preview' : '']);
const wrapperStylesRef = ref<Record<string, string>>({});
const hideFooterRef = ref(false);
const route = useRoute();
const router = useRouter();
const config = useRuntimeConfig();
const isDebugCookieEnabled = Boolean($cookie.get(constants.COOKIE.DEBUG));
const { setActiveCategory, activeCategoryPathRef } = useMopRouter();
const { isEpoqConsentAccepted, isDatadogRUMConsentAccepted } = useMopUsercentricsConsentClient();
const { initSaiz } = useMopSaizClient();
const { openCountrySelector, enforceCountrySelect, storeSelectedCountry } = useMopCountrySelector();
const { loadingRef } = useMopPageTransitionClient();
const { getHeadObject } = useMopSeo();
const { openSuperBannerOverlay, cmsSuperBannerStoryModelRef } = useMopSuperBannerClient();
const overlay = useMopOverlay('global');
const { cmsGlobalStoryListModelRef } = useMopCmsGlobalComponents();
const { addToast } = useMopToastsClient();

const { customerModelRef } = useMopCustomer();
const shouldSuperBannerBeRendered = !isClient || cmsGlobalStoryListModelRef.value.isInitialized();
const isGuestBuyerPopupVisibleRef = ref(false);

const superBannerData = shouldSuperBannerBeRendered ? getCmsSuperBanner(cmsSuperBannerStoryModelRef.value!) : undefined;
// flag isNotIndexable: true -> will lead to "noindex, follow". only index value changes, it should always be "follow".
const isIndexable =
  route.meta?.isNotIndexable !== true && !constants.DISALLOWED_ALTERNATE_SLUGS.includes($mopI18n.locale);
const showSelfReferencingCanonical = isIndexable && route.meta?.hideSelfReferencingCanonical !== true;
// default canonical: self referencing. Overwrite on route by setting hideSelfReferencingCanonical or in view template
const canonical = showSelfReferencingCanonical ? route.path : '';
const titleTemplate = `%s | ${$mopI18n.t('common.brandName')}`;
const title = $mopI18n.t('common.meta.default.title');
const description = $mopI18n.t('common.meta.default.description');
const seoObject = getHeadObject({
  title,
  description,
  isIndexable,
  canonical,
});
const loadAbtasty =
  config.public.ABTASTY_ENABLED &&
  $mopConfig.isAbtastyEnabled() &&
  !route.query.disableabtasty &&
  customerModelRef.value.isRealUser();
const scriptTagsRef = ref<any>([]);
const fontBaseURL = config.app.cdnURL + config.app.baseURL;
const linkTagsRef = ref<any>([
  {
    rel: 'preload',
    href: `${fontBaseURL}fonts/MOPGothic-Regular.woff2`,
    as: 'font',
    type: 'font/woff2',
    crossorigin: 'anonymous',
  },
  {
    rel: 'preload',
    href: `${fontBaseURL}fonts/MOPGothic-Bold.woff2`,
    as: 'font',
    type: 'font/woff2',
    crossorigin: 'anonymous',
  },
]);
let hideFooterTimer: Timer;
let stopGuestBuyerPopupWatcher: WatchStopHandle;

if (loadAbtasty) {
  scriptTagsRef.value.push({
    src: EXTERNAL_LIBS.AB_TASTY.replace('{ID}', $mopConfig.getAbtastyId()),
    type: 'text/javascript',
    async: $mopConfig.loadAbtastyAsync() ? 'async' : undefined,
    id: 'AB_TASTY',
  });
}

if ($mopI18n.isGlobalE) {
  linkTagsRef.value.push({
    href: `//${config.public.GLOBALE_SCRIPT_DOMAIN}/includes/css/895?countryCode=${$mopI18n.country.toUpperCase()}`,
    id: 'GEPIStyles',
    rel: 'stylesheet',
  });
}

if (config.app.cdnURL) {
  linkTagsRef.value.push({
    href: config.app.cdnURL,
    id: 'preconnect',
    rel: 'preconnect',
  });
  linkTagsRef.value.push({
    href: config.app.cdnURL,
    id: 'dns-prefetch',
    rel: 'dns-prefetch',
  });
}
if (config.public.CT_HOST) {
  linkTagsRef.value.push({
    href: config.public.CT_HOST,
    id: 'preconnect-CT_HOST',
    rel: 'preconnect',
    crossorigin: 'anonymous',
  });
}
if (config.public.CT_AWS_BFF_URL) {
  linkTagsRef.value.push({
    href: config.public.CT_AWS_BFF_URL,
    id: 'preconnect-CT_AWS_BFF_URL',
    rel: 'preconnect',
    crossorigin: 'anonymous',
  });
}

useHead({
  ...seoObject,
  titleTemplate,
  htmlAttrs: {
    lang: $mopI18n.lang,
  },
  script: scriptTagsRef,
  link: linkTagsRef,
});

function loadErrorPage() {
  errorRef.value = true;
  if (requestEvent?.node && !requestEvent.node?.res?.getHeader('location')) {
    requestEvent.node.res.statusCode = 404;
  }
}

onErrorCaptured((error, vm, info) => {
  nuxtApp.callHook('vue:error', error, vm, info);

  if (!error.message?.includes(ERROR_TYPE.MISSING_ASSET)) {
    logError(error);
  }

  // stops further error propagation
  return false;
});

router.beforeEach(async (to, from) => {
  errorRef.value = false;
  await routeChangeMiddleware(to, from);
});

async function routeChangeMiddleware(route: RouteLocationNormalized, from?: RouteLocationNormalized) {
  if (await isDatadogRUMConsentAccepted()) {
    $datadog.initDataDogBasedOnRoute(route);
    $datadog.setUserContext(customerModelRef.value);
  }

  await handleOverlays();
  // reset app path to top category for all paths except defined ones
  if (!route.meta?.disableActiveCategoryReset) {
    if (activeCategoryPathRef.value.length > 1) {
      setActiveCategory(activeCategoryPathRef.value[0].getId());
    }
  }
  async function handleOverlays(): Promise<void> {
    if (route.name) {
      overlay.canCloseOnRouteChange(route.name.toString()) && (await overlay.closeAll());
    }
    if (route.hash && overlay.openFromUrlHash(route.hash?.substring(1))) {
      router.replace(router.currentRoute.value.fullPath).catch((error) => {
        if (isNavigationFailure(error, NavigationFailureType.duplicated)) {
          return;
        }
        logError(error);
      });
    }
  }

  // If auth not after cart or password reset - clear redirect setting
  const isAuthPage = route.name === 'auth-login' || route.name === 'auth-register';
  if (
    isAuthPage &&
    from?.name !== 'cart' &&
    from?.name !== 'auth-login' &&
    from?.name !== 'auth-register' &&
    localStorageGet(constants.LOCAL_STORAGE.AFTER_AUTH_REDIRECT)
  ) {
    localStorageRemove(constants.LOCAL_STORAGE.AFTER_AUTH_REDIRECT);
  }
}

function initDebugData() {
  if (isClient) {
    window.debugData = {
      currentStoryId: $storyblokLivePreview.currentStoryModelIdRef,
    };
  }
}

onMounted(() => {
  routeChangeMiddleware(route);

  useMopUsercentricsConsentClient().initScript();

  if ($mopI18n.isGlobalE) {
    initGlobalECookie();
    loadScript({
      source: `//${config.public.GLOBALE_SCRIPT_DOMAIN}/includes/js/895`,
    });
  } else {
    $cookie.remove(constants.COOKIE.GLOBAL_E_DATA);
  }

  const { hasUserInteractedRef } = useMopGlobalEventsClient();

  // On cart page we fetch own cart data with recalculations
  if (
    (
      [
        constants.ROUTE.NAMES.CART,
        constants.ROUTE.NAMES.CHECKOUT.ROOT,
        constants.ROUTE.NAMES.CHECKOUT.ADDRESSES,
        constants.ROUTE.NAMES.CHECKOUT.BILLING,
        constants.ROUTE.NAMES.CHECKOUT.PAYMENT,
        constants.ROUTE.NAMES.CHECKOUT.SHIPPING,
        constants.ROUTE.NAMES.CHECKOUT.GLOBALE,
      ] as string[]
    ).includes(route.name as string) === false
  ) {
    useMopCartClient().initCart();
  }

  initDebugData();

  if (route.query.passwordReset) {
    addToast({
      content: $mopI18n.t('account.update.success.password'),
      status: 'success',
      showCloseButton: true,
      showIcon: true,
    });
  }

  const stopUserInteractedWatch = watch(
    hasUserInteractedRef,
    (hasInteracted) => {
      if (hasInteracted) {
        useMopWishlistClient().initWishlist();
        initChat();
        initEpoq();
        initSaiz();
        $gtm2.initTracking();
        initOverlays();
        initGuestBuyerPopup();
        nextTick(() => stopUserInteractedWatch());
      }
    },
    { immediate: true },
  );

  watch(
    () => route.hash,
    () => {
      scrollToAnchor(route.hash);
    },
    { immediate: true },
  );

  const superBannerElement = document.getElementsByClassName('superbanner-wrapper')[0];
  if (superBannerElement) {
    watch(
      $scroll.offsetTopRef,
      (offsetTop: number) => {
        const superbannerTopOffset = Math.max(0, parseInt(cssExport['theme-superbanner-height']) - offsetTop);
        // --sb is specifying how much (px) of the super banner is visible
        wrapperStylesRef.value = {
          ...wrapperStylesRef.value,
          '--sb': `${superbannerTopOffset}px`,
        };
      },
      { immediate: true },
    );
  }

  watch(
    $resize.viewportHeightRef,
    (viewportHeight) => {
      // used to calculate correct vh on ios devices, since vh is not working correctly
      wrapperStylesRef.value = {
        ...wrapperStylesRef.value,
        '--vh': `${viewportHeight}px`,
      };
    },
    { immediate: true },
  );

  watch(
    $resize.viewportOrientationRef,
    () => {
      // used to calculate correct vh on ios devices, since vh is not working correctly
      wrapperStylesRef.value = {
        ...wrapperStylesRef.value,
        '--mvh': `${$resize.viewportHeightRef.value}px`,
      };
    },
    { immediate: true },
  );
});

const isChatVisibleRef = computed(() => {
  return $mopConfig.isChatEnabled() && !route.query.disablechat && !route.meta.disableChat;
});

function initChat(): void {
  const loadChat = $mopConfig.isChatEnabled() && !route.query.disablechat && customerModelRef.value.isRealUser();
  if (!loadChat) {
    return;
  }
  const departmentId = $mopConfig.getChatDepartmentId();
  window.rpChatConfig = window.rpChatConfig || {};
  window.rpChatConfig.deptId = departmentId;
  window.rpChatConfig.pagename = '';
  loadScript({
    source: `https://${$mopConfig.getChatOrgId()}.realperson.cloud/system/scripts/loadchatmodul.js`,
    id: 'realpersonChatLoader',
  });

  // Currently not needed
  // const departmentIdForDisabledChat = 5;
  // watch(
  //   () => route.meta?.disableChat,
  //   (disableChat) => {
  //     window.rpChatConfig.deptId = disableChat ? departmentIdForDisabledChat : departmentId;
  //     if (window.rp) {
  //       window.rp.chat.changePage();
  //     }
  //   },
  //   { immediate: true },
  // );
}

async function initEpoq() {
  if ($mopConfig.getEpoqTenantId()) {
    if (customerModelRef.value.getCustomerNumber() && (await isEpoqConsentAccepted())) {
      scriptTagsRef.value.push({
        children: `epoq_customerId = '${customerModelRef.value.getCustomerNumber()}'`,
      });
    }

    scriptTagsRef.value.push({
      src: EXTERNAL_LIBS.EPOQ_SNIPPET.replace('{TENANT_ID}', $mopConfig.getEpoqTenantId()),
      type: 'text/javascript',
      async: 'async',
      id: 'EPOQ_SNIPPET',
    });
  }
}

function initGlobalECookie() {
  const globalECookie: any = {
    countryISO: $mopI18n.country.toUpperCase(),
    currencyCode: $mopI18n.geCurrency,
    cultureCode: $mopI18n.country.toLowerCase(),
  };

  $cookie.store(
    constants.COOKIE.GLOBAL_E_DATA,
    JSON.stringify(globalECookie),
    isProductionBuild ? { domain: 'marc-o-polo.com' } : {},
  );
}

function initGuestBuyerPopup() {
  if (
    !$mopConfig.isGuestBuyerMarketingEnabled() ||
    !customerModelRef.value.isGuest() ||
    $mopI18n.country !== 'de' ||
    localStorageGet(constants.LOCAL_STORAGE.GUEST_BUYER_HOME_POPUP_SHOWN)
  ) {
    return;
  }
  stopGuestBuyerPopupWatcher = watch(
    () => route.fullPath,
    () => {
      setTimeout(() => {
        isGuestBuyerPopupVisibleRef.value =
          !localStorageGet(constants.LOCAL_STORAGE.GUEST_BUYER_HOME_POPUP_SHOWN) &&
          route.name === constants.ROUTE.NAMES.CATEGORY_PAGE &&
          activeCategoryPathRef.value.length === 1 &&
          activeCategoryPathRef.value[0].isHomeCategory();
        if (isGuestBuyerPopupVisibleRef.value) {
          localStorageSet(constants.LOCAL_STORAGE.GUEST_BUYER_HOME_POPUP_SHOWN, '1');
        } else if (localStorageGet(constants.LOCAL_STORAGE.GUEST_BUYER_HOME_POPUP_SHOWN)) {
          stopGuestBuyerPopupWatcher();
        }
      }, 200);
    },
    { immediate: true },
  );
}

async function initOverlays() {
  if (overlay?.activeOverlayRef?.value?.isOpen) {
    return;
  }
  // Check country switch overlay
  if (await enforceCountrySelect()) {
    await openCountrySelector(initOverlays);
    storeSelectedCountry();
    return;
  }

  // Check superbanner overlay
  const superBannerUuid = superBannerData?._uid;
  if (
    superBannerUuid &&
    route.meta?.hideSuperbanner !== true &&
    superBannerData?.autoOpen &&
    superBannerData?.hasToggle &&
    !$breakpoint.isTinyRef.value &&
    localStorageGet(constants.LOCAL_STORAGE.SUPER_BANNER) !== superBannerUuid
  ) {
    localStorageSet(constants.LOCAL_STORAGE.SUPER_BANNER, superBannerUuid);
    await openSuperBannerOverlay();
  }
  /**
   * Our overlays are triggered by anchors ("#overlay-…"), but we don't want to safe them to the browsers history,
   * because that would reopen overlays on backwards navigation. In our "route change" middleware we take care for
   * that easily, but "route change" is not triggered when you enter the site—so we need the following additional
   * solution to handle this case. You can't use the "replace()" function by vue-router, because that would
   * trigger the "route change", which already touches the overlays, and would interfere with this solution.
   *
   * Using the "afterEach()" hook of the vue-router also doesn't work. You get an hydration issue due to SSR.
   */
  if (overlay.openFromUrlHash(route.hash?.substr(1))) {
    window.history.replaceState('', document.title, window.location.pathname + window.location.search);
  }
}
watch(loadingRef, (isLoading) => {
  clearTimeout(hideFooterTimer);
  if (isLoading) {
    hideFooterRef.value = true;
    return;
  }
  hideFooterTimer = setTimeout(() => {
    hideFooterRef.value = false;
  }, 500);
});

const superBannerClassName = 'wrapper--super-banner';
const hideSuperBannerClassName = 'wrapper--super-banner-hidden';
watch(
  () => route.meta?.hideSuperbanner,
  (hideSuperbanner) => {
    if (superBannerData && hideSuperbanner !== true) {
      addValuesToArrayRef(classNameRef, superBannerClassName);
      removeValuesFromArrayRef(classNameRef, hideSuperBannerClassName);
    } else {
      removeValuesFromArrayRef(classNameRef, superBannerClassName);
      addValuesToArrayRef(classNameRef, hideSuperBannerClassName);
    }
  },
  { immediate: true },
);

const hideFooterClassName = 'wrapper--footer-hidden';
watch(
  () => route.meta?.hideFooter,
  (hideFooter) => {
    if (hideFooter === true) {
      addValuesToArrayRef(classNameRef, hideFooterClassName);
    } else {
      removeValuesFromArrayRef(classNameRef, hideFooterClassName);
    }
  },
  { immediate: true },
);
</script>

<template>
  <div v-if="$maintenance.isActive" class="maintenance">
    <MopMaintenance />
  </div>
  <div v-else id="wrapper" :class="['wrapper', ...classNameRef]" :style="wrapperStylesRef">
    <div class="wrapper__content">
      <MopStoryblokLinkWidget v-if="isDebugCookieEnabled" />
      <MopSuperBanner class="wrapper__superbanner" />
      <MopHeader class="wrapper__header" />
      <div :class="['wrapper__body', `wrapper__body--${String($route.name)}`, 'lock-scroll-padding']">
        <LazyMopNotFound v-if="errorRef" />
        <NuxtPage @page-not-found="loadErrorPage" />
      </div>

      <ClientOnly>
        <MopToastsWrapper />
        <LazyMopFooter
          :class="{
            'wrapper__footer-hidden': hideFooterRef,
          }"
        />
      </ClientOnly>
    </div>

    <ClientOnly>
      <LazyMopProductSaizWidget />
      <LazyMopOverlayManager group="global" show-shadow />
      <LazyMopGuestBuyerPopup v-if="isGuestBuyerPopupVisibleRef" />
      <span v-show="isChatVisibleRef" id="optiRealPersonContent">
        <!--RealPerson Content-->
      </span>
      <div id="debug-data" :data-current-story-id="$storyblokLivePreview.currentStoryModelIdRef.value"></div>
    </ClientOnly>
  </div>
</template>
